interface ViewerUrls {
  google: string;
  microsoft: string;
  direct: string;
}

export const getDocumentViewerUrl = (fileUrl: string): ViewerUrls => {
  const encodedUrl = encodeURIComponent(fileUrl);
  
  return {
    google: `https://docs.google.com/viewer?url=${encodedUrl}&embedded=true`,
    microsoft: `https://view.officeapps.live.com/op/view.aspx?src=${encodedUrl}`,
    direct: fileUrl
  };
};

export const isGoogleDocsUrl = (url: string): boolean => {
  return /^https:\/\/(docs|drive)\.google\.com/.test(url);
};

export const isMicrosoftUrl = (url: string): boolean => {
  return /^https:\/\/(onedrive\.live\.com|.*\.sharepoint\.com)/.test(url);
};

export const getDocumentService = (url: string): 'google' | 'microsoft' | 'other' => {
  if (isGoogleDocsUrl(url)) return 'google';
  if (isMicrosoftUrl(url)) return 'microsoft';
  return 'other';
};