import { useState, useEffect } from 'react';
import { onSnapshot, query, where, collection } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { useFirestore } from './useFirestore';
import type { UserSettings } from '../types';

export const useUserSettings = () => {
  const [settings, setSettings] = useState<UserSettings | null>(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { add, update } = useFirestore<UserSettings>('userSettings');

  useEffect(() => {
    if (!user) {
      setSettings(null);
      setLoading(false);
      return;
    }

    const q = query(
      collection(db, 'userSettings'),
      where('userId', '==', user.uid)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        const settingsData = {
          id: snapshot.docs[0].id,
          ...snapshot.docs[0].data()
        } as UserSettings;
        setSettings(settingsData);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const updateSettings = async (data: Partial<UserSettings>) => {
    if (!settings) {
      await add({
        hourlyRate: data.hourlyRate || 0,
        currency: data.currency || 'USD',
        userId: user!.uid,
        subscription: 'basic'
      } as UserSettings);
    } else {
      await update(settings.id, data);
    }
  };

  return {
    settings,
    loading,
    updateSettings
  };
};