import React, { Component, ErrorInfo, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundaryClass extends Component<Props & { 
  navigate: (path: string) => void;
  pathname: string;
}, State> {
  public state: State = {
    hasError: false,
    error: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public componentDidUpdate(prevProps: Props & { pathname: string }) {
    if (this.state.hasError && this.props.pathname !== prevProps.pathname) {
      this.setState({ hasError: false, error: null });
    }
  }

  public render() {
    if (this.state.hasError) {
      if (this.props.pathname !== '/') {
        toast.error('Something went wrong. Redirecting to dashboard...');
        setTimeout(() => {
          this.props.navigate('/', { replace: true });
        }, 0);
        return null;
      }
      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          <div className="text-center">
            <h1 className="text-2xl font-bold text-gray-900 mb-4">Something went wrong</h1>
            <p className="text-gray-600 mb-4">{this.state.error?.message}</p>
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Refresh Page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  return (
    <ErrorBoundaryClass 
      navigate={navigate} 
      pathname={location.pathname}
    >
      {children}
    </ErrorBoundaryClass>
  );
};

export default ErrorBoundary;