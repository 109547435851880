import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { useMatters } from '../../hooks/useMatters';
import { useClients } from '../../hooks/useClients';
import toast from 'react-hot-toast';
import type { Matter } from '../../types';

interface NewMatterFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  editMatter?: Matter | null;
}

const NewMatterForm: React.FC<NewMatterFormProps> = ({ 
  isOpen, 
  onClose, 
  onSuccess,
  editMatter 
}) => {
  const { addMatter, updateMatter } = useMatters();
  const { clients } = useClients();
  const [formData, setFormData] = useState({
    title: '',
    clientId: '',
    status: 'pending',
    billingType: 'hourly',
    description: '',
    startDate: '',
    dueDate: ''
  });

  useEffect(() => {
    if (editMatter) {
      setFormData({
        title: editMatter.title,
        clientId: editMatter.clientId,
        status: editMatter.status,
        billingType: editMatter.billingType,
        description: editMatter.description,
        startDate: editMatter.startDate,
        dueDate: editMatter.dueDate || ''
      });
    } else {
      setFormData({
        title: '',
        clientId: '',
        status: 'pending',
        billingType: 'hourly',
        description: '',
        startDate: '',
        dueDate: ''
      });
    }
  }, [editMatter]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (editMatter) {
        await updateMatter(editMatter.id, formData);
        toast.success('Matter updated successfully');
      } else {
        await addMatter(formData);
        toast.success('Matter created successfully');
      }
      onSuccess();
      onClose();
    } catch (error) {
      toast.error(editMatter ? 'Failed to update matter' : 'Failed to create matter');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-2xl">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">
            {editMatter ? 'Edit Matter' : 'New Matter'}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Title
              </label>
              <input
                type="text"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Client
              </label>
              <select
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.clientId}
                onChange={(e) => setFormData(prev => ({ ...prev, clientId: e.target.value }))}
              >
                <option value="">Select a client</option>
                {clients.map(client => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Status
                </label>
                <select
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  value={formData.status}
                  onChange={(e) => setFormData(prev => ({ ...prev, status: e.target.value }))}
                >
                  <option value="pending">Pending</option>
                  <option value="active">Active</option>
                  <option value="closed">Closed</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Billing Type
                </label>
                <select
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  value={formData.billingType}
                  onChange={(e) => setFormData(prev => ({ ...prev, billingType: e.target.value }))}
                >
                  <option value="hourly">Hourly</option>
                  <option value="fixed">Fixed Fee</option>
                  <option value="contingency">Contingency</option>
                </select>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                required
                rows={3}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Start Date
                </label>
                <input
                  type="date"
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  value={formData.startDate}
                  onChange={(e) => setFormData(prev => ({ ...prev, startDate: e.target.value }))}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Due Date
                </label>
                <input
                  type="date"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  value={formData.dueDate}
                  onChange={(e) => setFormData(prev => ({ ...prev, dueDate: e.target.value }))}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            >
              {editMatter ? 'Update Matter' : 'Create Matter'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewMatterForm;