import React from 'react';
import { CreditCard } from 'lucide-react';

const SubscriptionSettings = () => {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="mb-8">
        <h2 className="text-xl font-semibold">Subscription Plan</h2>
        <p className="text-gray-500 mt-1">Manage your subscription settings</p>
      </div>

      <div className="max-w-lg mx-auto">
        <div className="border rounded-xl p-6">
          <a
            href="https://billing.stripe.com/p/login/7sI00v8vP2TQ2Qg000"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full px-4 py-2 rounded-lg flex items-center justify-center gap-2 bg-blue-600 text-white hover:bg-blue-700"
          >
            <CreditCard className="w-4 h-4" />
            <span>Manage Subscription</span>
          </a>
          <p className="mt-4 text-sm text-gray-500 text-center">
            Your subscription will automatically renew unless cancelled before the renewal date.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSettings;