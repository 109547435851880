import React from 'react';
import { format } from 'date-fns';
import { Clock } from 'lucide-react';
import { useTimeEntries } from '../../hooks/useTimeEntries';
import { useMatters } from '../../hooks/useMatters';

const RecentTimeEntries = () => {
  const { timeEntries } = useTimeEntries();
  const { matters } = useMatters();

  const formatDate = (timestamp: any) => {
    try {
      const date = timestamp?.toDate?.() || new Date(timestamp);
      return format(date, 'MMM d, yyyy h:mm a');
    } catch (error) {
      return 'Invalid date';
    }
  };

  const getMatterTitle = (matterId: string) => {
    return matters.find(m => m.id === matterId)?.title || 'Unknown Matter';
  };

  const recentEntries = timeEntries
    .sort((a, b) => {
      try {
        const dateA = a.createdAt?.toDate?.() || new Date(a.createdAt);
        const dateB = b.createdAt?.toDate?.() || new Date(b.createdAt);
        return dateB.getTime() - dateA.getTime();
      } catch (error) {
        return 0;
      }
    })
    .slice(0, 5);

  return (
    <div className="bg-white rounded-xl shadow-sm">
      <div className="p-6">
        <div className="flex items-center gap-2 mb-4">
          <Clock className="w-5 h-5 text-blue-600" />
          <h2 className="text-lg font-semibold">Recent Time Entries</h2>
        </div>
        {recentEntries.length === 0 ? (
          <p className="text-gray-500 text-center py-4">No recent time entries</p>
        ) : (
          <div className="space-y-4">
            {recentEntries.map((entry) => (
              <div key={entry.id} className="flex items-start gap-4 p-3 bg-gray-50 rounded-lg">
                <div className="flex-1">
                  <div className="flex justify-between">
                    <p className="font-medium text-gray-900">{getMatterTitle(entry.matterId)}</p>
                    <span className="text-sm text-gray-500">{formatDate(entry.createdAt)}</span>
                  </div>
                  <p className="text-sm text-gray-600 mt-1">{entry.description}</p>
                  <div className="flex gap-4 mt-2 text-sm text-gray-500">
                    <span>{entry.hours} hours</span>
                    <span>${entry.rate}/hr</span>
                    <span className="font-medium">${(entry.hours * entry.rate).toFixed(2)}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentTimeEntries;