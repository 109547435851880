import { 
  collection, 
  query, 
  where, 
  orderBy, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  getDocs,
  Timestamp,
  DocumentData,
  Query,
  CollectionReference,
  enableNetwork,
  disableNetwork
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { Matter, Client, Document, CalendarEvent } from '../types';

// Generic type for Firestore documents
type FirestoreDoc = Matter | Client | Document | CalendarEvent;

export const useFirestore = <T extends FirestoreDoc>(collectionName: string) => {
  const { user } = useAuth();

  const getCollectionRef = (): CollectionReference => {
    return collection(db, collectionName);
  };

  const handleError = async (error: any) => {
    console.error(`Firestore error in ${collectionName}:`, error);

    if (error.code === 'unavailable') {
      try {
        await disableNetwork(db);
        await enableNetwork(db);
      } catch (reconnectError) {
        console.error('Failed to reconnect:', reconnectError);
      }
    }

    // Instead of throwing, return null to handle the error gracefully
    return null;
  };

  const add = async (data: Omit<T, 'id'>) => {
    if (!user) return null;
    
    const docData = {
      ...data,
      userId: user.uid,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    };

    try {
      const docRef = await addDoc(getCollectionRef(), docData);
      return { id: docRef.id, ...docData };
    } catch (error) {
      return handleError(error);
    }
  };

  const update = async (id: string, data: Partial<T>) => {
    if (!user) return null;

    try {
      const docRef = doc(getCollectionRef(), id);
      const docSnap = await getDocs(query(
        getCollectionRef(),
        where('userId', '==', user.uid)
      ));

      if (!docSnap.docs.some(doc => doc.id === id)) {
        return null;
      }

      const updateData = {
        ...data,
        updatedAt: Timestamp.now()
      };

      await updateDoc(docRef, updateData);
      return { id, ...updateData };
    } catch (error) {
      return handleError(error);
    }
  };

  const remove = async (id: string) => {
    if (!user) return null;

    try {
      const docRef = doc(getCollectionRef(), id);
      const docSnap = await getDocs(query(
        getCollectionRef(),
        where('userId', '==', user.uid)
      ));

      if (!docSnap.docs.some(doc => doc.id === id)) {
        return null;
      }

      await deleteDoc(docRef);
      return true;
    } catch (error) {
      return handleError(error);
    }
  };

  const getQuery = (): Query => {
    if (!user) throw new Error('No authenticated user');

    return query(
      getCollectionRef(),
      where('userId', '==', user.uid),
      orderBy('createdAt', 'desc')
    );
  };

  const getById = async (id: string): Promise<T & DocumentData | null> => {
    if (!user) return null;

    try {
      const docRef = doc(getCollectionRef(), id);
      const docSnap = await getDocs(query(
        getCollectionRef(),
        where('userId', '==', user.uid)
      ));

      const document = docSnap.docs.find(doc => doc.id === id);
      if (!document) {
        return null;
      }

      return { id: document.id, ...document.data() } as T & DocumentData;
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    add,
    update,
    remove,
    getQuery,
    getById
  };
};