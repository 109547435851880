import React, { useState, useEffect } from 'react';
import { useUserSettings } from '../../hooks/useUserSettings';
import toast from 'react-hot-toast';

const InvoiceSettings = () => {
  const { settings, updateSettings } = useUserSettings();
  const [formData, setFormData] = useState({
    invoicePrefix: 'INV-',
    paymentTerms: 'Net 30',
    invoiceNotes: 'Thank you for your business!',
    bankDetails: '',
    taxRate: '0',
    currency: 'USD',
    footerText: ''
  });

  useEffect(() => {
    if (settings?.invoiceSettings) {
      setFormData({
        invoicePrefix: settings.invoiceSettings.invoicePrefix || 'INV-',
        paymentTerms: settings.invoiceSettings.paymentTerms || 'Net 30',
        invoiceNotes: settings.invoiceSettings.invoiceNotes || 'Thank you for your business!',
        bankDetails: settings.invoiceSettings.bankDetails || '',
        taxRate: settings.invoiceSettings.taxRate?.toString() || '0',
        currency: settings.invoiceSettings.currency || 'USD',
        footerText: settings.invoiceSettings.footerText || ''
      });
    }
  }, [settings]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updateSettings({
        invoiceSettings: {
          ...formData,
          taxRate: parseFloat(formData.taxRate)
        }
      });
      toast.success('Invoice settings updated successfully');
    } catch (error) {
      toast.error('Failed to update invoice settings');
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-xl font-semibold mb-6">Invoice Settings</h2>
      <form onSubmit={handleSubmit} className="space-y-6 max-w-2xl">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Invoice Number Prefix
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
              value={formData.invoicePrefix}
              onChange={(e) => setFormData(prev => ({ ...prev, invoicePrefix: e.target.value }))}
              placeholder="e.g., INV-"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Payment Terms
            </label>
            <select
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
              value={formData.paymentTerms}
              onChange={(e) => setFormData(prev => ({ ...prev, paymentTerms: e.target.value }))}
            >
              <option value="Due on Receipt">Due on Receipt</option>
              <option value="Net 15">Net 15</option>
              <option value="Net 30">Net 30</option>
              <option value="Net 45">Net 45</option>
              <option value="Net 60">Net 60</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Currency
            </label>
            <select
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
              value={formData.currency}
              onChange={(e) => setFormData(prev => ({ ...prev, currency: e.target.value }))}
            >
              <option value="USD">USD - US Dollar</option>
              <option value="EUR">EUR - Euro</option>
              <option value="GBP">GBP - British Pound</option>
              <option value="CAD">CAD - Canadian Dollar</option>
              <option value="AUD">AUD - Australian Dollar</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Tax Rate (%)
            </label>
            <input
              type="number"
              min="0"
              max="100"
              step="0.01"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
              value={formData.taxRate}
              onChange={(e) => setFormData(prev => ({ ...prev, taxRate: e.target.value }))}
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Bank/Payment Details
          </label>
          <textarea
            rows={3}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
            value={formData.bankDetails}
            onChange={(e) => setFormData(prev => ({ ...prev, bankDetails: e.target.value }))}
            placeholder="Enter your bank account or payment details"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Invoice Notes
          </label>
          <textarea
            rows={2}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
            value={formData.invoiceNotes}
            onChange={(e) => setFormData(prev => ({ ...prev, invoiceNotes: e.target.value }))}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Footer Text
          </label>
          <textarea
            rows={2}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
            value={formData.footerText}
            onChange={(e) => setFormData(prev => ({ ...prev, footerText: e.target.value }))}
            placeholder="Additional information to display at the bottom of invoices"
          />
        </div>

        <div>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default InvoiceSettings;