import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { useTimeEntries } from '../../hooks/useTimeEntries';
import { useMatters } from '../../hooks/useMatters';
import { useClients } from '../../hooks/useClients';
import { useUserSettings } from '../../hooks/useUserSettings';
import { useSnippets } from '../../hooks/useSnippets';
import type { TimeEntry, Matter } from '../../types';
import toast from 'react-hot-toast';

interface NewTimeEntryFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  editEntry?: TimeEntry;
}

const NewTimeEntryForm: React.FC<NewTimeEntryFormProps> = ({
  isOpen,
  onClose,
  onSuccess,
  editEntry
}) => {
  const { addTimeEntry, updateTimeEntry } = useTimeEntries();
  const { matters } = useMatters();
  const { clients } = useClients();
  const { settings } = useUserSettings();
  const { snippets } = useSnippets();
  const [selectedMatter, setSelectedMatter] = useState<Matter | null>(null);
  const [includeTime, setIncludeTime] = useState(false);
  const [formData, setFormData] = useState({
    matterId: '',
    clientId: '',
    description: '',
    hours: '',
    rate: '',
    fixedFeeTitle: '',
    fixedFeeAmount: '',
    date: new Date().toISOString().split('T')[0],
    billable: false
  });

  // Calculate total amount based on billing type
  const totalAmount = selectedMatter?.billingType === 'fixed'
    ? formData.fixedFeeAmount || '0.00'
    : (formData.hours && formData.rate
      ? (parseFloat(formData.hours) * parseFloat(formData.rate)).toFixed(2)
      : '0.00');

  useEffect(() => {
    if (editEntry) {
      const matter = matters.find(m => m.id === editEntry.matterId);
      setSelectedMatter(matter || null);
      setIncludeTime(!!editEntry.hours);
      setFormData({
        matterId: editEntry.matterId,
        clientId: editEntry.clientId,
        description: editEntry.description,
        hours: editEntry.hours?.toString() || '',
        rate: editEntry.rate?.toString() || '',
        fixedFeeTitle: editEntry.fixedFeeTitle || '',
        fixedFeeAmount: editEntry.fixedFeeAmount?.toString() || '',
        date: editEntry.date,
        billable: editEntry.billable
      });
    } else if (settings) {
      setFormData(prev => ({
        ...prev,
        rate: settings.hourlyRate.toString()
      }));
    }
  }, [editEntry, settings, matters]);

  const handleMatterChange = (matterId: string) => {
    const matter = matters.find(m => m.id === matterId);
    setSelectedMatter(matter || null);
    setFormData(prev => ({
      ...prev,
      matterId,
      clientId: matter?.clientId || '',
      hours: '',
      rate: settings?.hourlyRate.toString() || '',
      fixedFeeTitle: '',
      fixedFeeAmount: '',
      billable: matter?.billingType !== 'fixed'
    }));
    setIncludeTime(false);
  };

  const handleSnippetSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSnippet = snippets.find(s => s.id === e.target.value);
    if (selectedSnippet) {
      setFormData(prev => ({
        ...prev,
        description: selectedSnippet.content
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const timeEntryData = {
        ...formData,
        hours: (selectedMatter?.billingType === 'fixed' && !includeTime) ? null : parseFloat(formData.hours) || null,
        rate: (selectedMatter?.billingType === 'fixed' && !includeTime) ? null : parseFloat(formData.rate) || null,
        fixedFeeAmount: selectedMatter?.billingType === 'fixed' ? parseFloat(formData.fixedFeeAmount) : null,
        billable: selectedMatter?.billingType === 'fixed' ? formData.billable : true
      };

      if (editEntry) {
        await updateTimeEntry(editEntry.id, timeEntryData);
        toast.success('Time entry updated successfully');
      } else {
        await addTimeEntry(timeEntryData);
        toast.success('Time entry created successfully');
      }
      onSuccess();
      onClose();
    } catch (error) {
      toast.error(editEntry ? 'Failed to update time entry' : 'Failed to create time entry');
    }
  };

  const getClientName = (clientId: string) => {
    return clients.find(c => c.id === clientId)?.name || 'Unknown Client';
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-2xl">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">
            {editEntry ? 'Edit Time Entry' : 'New Time Entry'}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Matter
              </label>
              <select
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.matterId}
                onChange={(e) => handleMatterChange(e.target.value)}
              >
                <option value="">Select a matter</option>
                {matters.map(matter => (
                  <option key={matter.id} value={matter.id}>
                    {matter.title} ({matter.billingType})
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Client
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg bg-gray-50"
                value={selectedMatter ? getClientName(selectedMatter.clientId) : ''}
                disabled
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <div className="space-y-2">
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                onChange={handleSnippetSelect}
                value=""
              >
                <option value="">Select a snippet or type custom description</option>
                {snippets.map(snippet => (
                  <option key={snippet.id} value={snippet.id}>
                    {snippet.content}
                  </option>
                ))}
              </select>
              <textarea
                required
                rows={3}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              />
            </div>
          </div>

          {selectedMatter?.billingType === 'fixed' && (
            <>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Fixed Fee Title
                  </label>
                  <input
                    type="text"
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                    value={formData.fixedFeeTitle}
                    onChange={(e) => setFormData(prev => ({ ...prev, fixedFeeTitle: e.target.value }))}
                    placeholder="e.g., Initial Consultation, Document Review"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Fixed Fee Amount
                  </label>
                  <input
                    type="number"
                    required
                    min="0"
                    step="0.01"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                    value={formData.fixedFeeAmount}
                    onChange={(e) => setFormData(prev => ({ ...prev, fixedFeeAmount: e.target.value }))}
                  />
                </div>
              </div>

              <div className="flex items-center gap-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="rounded border-gray-300"
                    checked={includeTime}
                    onChange={(e) => setIncludeTime(e.target.checked)}
                  />
                  <span className="text-sm text-gray-700">Include time tracking</span>
                </label>
              </div>
            </>
          )}

          {(selectedMatter?.billingType !== 'fixed' || includeTime) && (
            <div className="grid grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Hours
                </label>
                <input
                  type="number"
                  required={selectedMatter?.billingType !== 'fixed'}
                  min="0.1"
                  step="0.1"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  value={formData.hours}
                  onChange={(e) => setFormData(prev => ({ ...prev, hours: e.target.value }))}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Rate ($/hour)
                </label>
                <input
                  type="number"
                  required={selectedMatter?.billingType !== 'fixed'}
                  min="0"
                  step="0.01"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  value={formData.rate}
                  onChange={(e) => setFormData(prev => ({ ...prev, rate: e.target.value }))}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Total Amount
                </label>
                <div className="w-full px-3 py-2 border border-gray-300 rounded-lg bg-gray-50">
                  ${totalAmount}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Date
                </label>
                <input
                  type="date"
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  value={formData.date}
                  onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
                />
              </div>
            </div>
          )}

          <div className="flex items-center">
            <input
              type="checkbox"
              id="billable"
              className="rounded border-gray-300"
              checked={formData.billable}
              onChange={(e) => setFormData(prev => ({ ...prev, billable: e.target.checked }))}
            />
            <label htmlFor="billable" className="ml-2 text-sm text-gray-700">
              Billable
            </label>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            >
              {editEntry ? 'Update Entry' : 'Create Entry'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewTimeEntryForm;