import { useState, useEffect } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { useFirestore } from './useFirestore';
import { useAuth } from '../contexts/AuthContext';
import type { Snippet } from '../types';

export const useSnippets = () => {
  const [snippets, setSnippets] = useState<Snippet[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { getQuery, add, update, remove } = useFirestore<Snippet>('snippets');

  useEffect(() => {
    if (!user) {
      setSnippets([]);
      setLoading(false);
      return;
    }

    try {
      const q = getQuery();
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const snippetsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Snippet));
        setSnippets(snippetsData);
        setLoading(false);
      });

      return () => unsubscribe();
    } catch (error) {
      console.error('Error fetching snippets:', error);
      setLoading(false);
    }
  }, [user]);

  return {
    snippets,
    loading,
    addSnippet: add,
    updateSnippet: update,
    deleteSnippet: remove
  };
};