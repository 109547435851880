import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence, initializeFirestore, CACHE_SIZE_UNLIMITED } from 'firebase/firestore';
import toast from 'react-hot-toast';

const firebaseConfig = {
  apiKey: "AIzaSyCoSkBkGSebnEIVHV_drnWfEZJeqr9-Fjw",
  authDomain: "boltlegalappv2.firebaseapp.com",
  projectId: "boltlegalappv2",
  storageBucket: "boltlegalappv2.firebasestorage.app",
  messagingSenderId: "64481521752",
  appId: "1:64481521752:web:f81608a932e51dfacd1a2d",
  measurementId: "G-CFP1ZR1ECE"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Initialize Firestore with settings for better offline support
export const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});

// Enable offline persistence
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled in one tab at a time
    toast.error('Multiple tabs open. Offline mode only works in one tab at a time.');
  } else if (err.code === 'unimplemented') {
    // The current browser doesn't support persistence
    toast.error('Your browser doesn\'t support offline mode.');
  }
});

// Add network status monitoring
let isOnline = true;

function updateOnlineStatus() {
  const wasOnline = isOnline;
  isOnline = navigator.onLine;
  
  if (wasOnline && !isOnline) {
    toast.error('You are offline. Changes will be synced when connection is restored.');
  } else if (!wasOnline && isOnline) {
    toast.success('Back online! Syncing changes...');
  }
}

window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);