import React, { useState } from 'react';
import { X, AlertTriangle, ExternalLink } from 'lucide-react';
import type { Client } from '../../types';
import { useMatters } from '../../hooks/useMatters';
import { useClients } from '../../hooks/useClients';
import { useDocumentTemplates } from '../../hooks/useDocumentTemplates';
import toast from 'react-hot-toast';

interface ClientDetailsModalProps {
  client: Client;
  isOpen: boolean;
  onClose: () => void;
  onEdit: () => void;
}

const ClientDetailsModal: React.FC<ClientDetailsModalProps> = ({
  client,
  isOpen,
  onClose,
  onEdit
}) => {
  const { matters } = useMatters();
  const { deleteClient } = useClients();
  const { templates } = useDocumentTemplates();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  
  if (!isOpen) return null;

  const clientMatters = matters.filter(m => m.clientId === client.id);
  const clientDocuments = templates.filter(t => t.clientId === client.id);

  const handleDelete = async () => {
    try {
      await deleteClient(client.id);
      toast.success('Client deleted successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to delete client');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-6 border-b sticky top-0 bg-white">
          <h2 className="text-xl font-semibold">Client Details</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-6 space-y-6">
          <div>
            <h3 className="text-lg font-semibold text-gray-900">{client.name}</h3>
            <p className="mt-1 text-gray-600">{client.email}</p>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <p className="text-sm font-medium text-gray-500">Phone</p>
              <p className="mt-1">{client.phone}</p>
            </div>

            <div>
              <p className="text-sm font-medium text-gray-500">Address</p>
              <p className="mt-1">{client.address}</p>
            </div>
          </div>

          {clientDocuments.length > 0 && (
            <div>
              <h4 className="text-sm font-medium text-gray-500 mb-3">Documents</h4>
              <div className="space-y-2">
                {clientDocuments.map(doc => (
                  <div key={doc.id} className="p-3 bg-gray-50 rounded-lg">
                    <div className="flex justify-between items-start">
                      <div>
                        <h5 className="font-medium text-gray-900">{doc.title}</h5>
                        <p className="text-sm text-gray-600">{doc.description}</p>
                        {doc.matterId && (
                          <p className="text-sm text-gray-500 mt-1">
                            Matter: {matters.find(m => m.id === doc.matterId)?.title}
                          </p>
                        )}
                        {doc.category && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mt-2">
                            {doc.category}
                          </span>
                        )}
                      </div>
                      <a
                        href={doc.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-700 inline-flex items-center gap-1"
                      >
                        <ExternalLink className="w-4 h-4" />
                        Open
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {clientMatters.length > 0 && (
            <div>
              <h4 className="text-sm font-medium text-gray-500 mb-3">Associated Matters</h4>
              <div className="space-y-2">
                {clientMatters.map(matter => (
                  <div key={matter.id} className="p-3 bg-gray-50 rounded-lg">
                    <div className="flex justify-between items-start">
                      <div>
                        <h5 className="font-medium text-gray-900">{matter.title}</h5>
                        <p className="text-sm text-gray-600">{matter.description}</p>
                      </div>
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        matter.status === 'active' ? 'bg-green-100 text-green-800' :
                        matter.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {matter.status}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex justify-between gap-3 pt-6 border-t">
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="px-4 py-2 text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg"
            >
              Delete Client
            </button>
            <div className="flex gap-3">
              <button
                onClick={onClose}
                className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                Close
              </button>
              <button
                onClick={onEdit}
                className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
              >
                Edit Client
              </button>
            </div>
          </div>
        </div>
      </div>

      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-lg w-full max-w-md p-6">
            <div className="flex items-center gap-3 text-red-600 mb-4">
              <AlertTriangle className="w-6 h-6" />
              <h3 className="text-lg font-semibold">Delete Client</h3>
            </div>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this client? This action cannot be undone.
              {clientMatters.length > 0 && (
                <span className="block mt-2 text-red-600">
                  Warning: This client has {clientMatters.length} associated matter{clientMatters.length > 1 ? 's' : ''}.
                </span>
              )}
              {clientDocuments.length > 0 && (
                <span className="block mt-2 text-red-600">
                  Warning: This client has {clientDocuments.length} associated document{clientDocuments.length > 1 ? 's' : ''}.
                </span>
              )}
            </p>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700"
              >
                Delete Client
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientDetailsModal;