import React, { useState } from 'react';
import { X, AlertTriangle, ExternalLink } from 'lucide-react';
import { format } from 'date-fns';
import type { Matter } from '../../types';
import { useClients } from '../../hooks/useClients';
import { useMatters } from '../../hooks/useMatters';
import { useDocumentTemplates } from '../../hooks/useDocumentTemplates';
import toast from 'react-hot-toast';

interface MatterDetailsModalProps {
  matter: Matter;
  isOpen: boolean;
  onClose: () => void;
  onEdit: () => void;
}

const MatterDetailsModal: React.FC<MatterDetailsModalProps> = ({
  matter,
  isOpen,
  onClose,
  onEdit
}) => {
  const { clients } = useClients();
  const { deleteMatter } = useMatters();
  const { templates } = useDocumentTemplates();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  
  if (!isOpen) return null;

  const client = clients.find(c => c.id === matter.clientId);
  const matterDocuments = templates.filter(t => t.matterId === matter.id);

  const getStatusBadgeColor = (status: Matter['status']) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'closed':
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getBillingTypeBadgeColor = (billingType: Matter['billingType']) => {
    switch (billingType) {
      case 'hourly':
        return 'bg-blue-100 text-blue-800';
      case 'fixed':
        return 'bg-purple-100 text-purple-800';
      case 'contingency':
        return 'bg-orange-100 text-orange-800';
    }
  };

  const handleDelete = async () => {
    try {
      await deleteMatter(matter.id);
      toast.success('Matter deleted successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to delete matter');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-2xl">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Matter Details</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-6 space-y-6">
          <div>
            <h3 className="text-lg font-semibold text-gray-900">{matter.title}</h3>
            <p className="mt-1 text-gray-600">{matter.description}</p>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <p className="text-sm font-medium text-gray-500">Client</p>
              <p className="mt-1">{client?.name || 'Unknown Client'}</p>
            </div>

            <div>
              <p className="text-sm font-medium text-gray-500">Status</p>
              <span className={`mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeColor(matter.status)}`}>
                {matter.status}
              </span>
            </div>

            <div>
              <p className="text-sm font-medium text-gray-500">Billing Type</p>
              <span className={`mt-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getBillingTypeBadgeColor(matter.billingType)}`}>
                {matter.billingType}
              </span>
            </div>

            <div>
              <p className="text-sm font-medium text-gray-500">Start Date</p>
              <p className="mt-1">{format(new Date(matter.startDate), 'MMM d, yyyy')}</p>
            </div>

            {matter.dueDate && (
              <div>
                <p className="text-sm font-medium text-gray-500">Due Date</p>
                <p className="mt-1">{format(new Date(matter.dueDate), 'MMM d, yyyy')}</p>
              </div>
            )}
          </div>

          {matterDocuments.length > 0 && (
            <div>
              <h4 className="text-sm font-medium text-gray-500 mb-3">Documents</h4>
              <div className="space-y-2">
                {matterDocuments.map(doc => (
                  <div key={doc.id} className="p-3 bg-gray-50 rounded-lg">
                    <div className="flex justify-between items-start">
                      <div>
                        <h5 className="font-medium text-gray-900">{doc.title}</h5>
                        <p className="text-sm text-gray-600">{doc.description}</p>
                      </div>
                      <a
                        href={doc.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-700 inline-flex items-center gap-1"
                      >
                        <ExternalLink className="w-4 h-4" />
                        Open
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex justify-between gap-3 pt-6 border-t">
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="px-4 py-2 text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg"
            >
              Delete Matter
            </button>
            <div className="flex gap-3">
              <button
                onClick={onClose}
                className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                Close
              </button>
              <button
                onClick={onEdit}
                className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
              >
                Edit Matter
              </button>
            </div>
          </div>
        </div>
      </div>

      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-lg w-full max-w-md p-6">
            <div className="flex items-center gap-3 text-red-600 mb-4">
              <AlertTriangle className="w-6 h-6" />
              <h3 className="text-lg font-semibold">Delete Matter</h3>
            </div>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this matter? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700"
              >
                Delete Matter
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MatterDetailsModal;