import React from 'react';
import { Plus } from 'lucide-react';

interface DashboardHeaderProps {
  onNewMatter: () => void;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ onNewMatter }) => {
  return (
    <div className="flex justify-between items-center">
      <h1 className="text-2xl font-bold">Dashboard</h1>
      <button
        onClick={onNewMatter}
        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
      >
        <Plus className="w-4 h-4" />
        New Matter
      </button>
    </div>
  );
};

export default DashboardHeader;