import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
  Users, 
  Briefcase, 
  FileText, 
  Calendar,
  LayoutDashboard,
  Settings,
  Clock,
  Scale,
  Mail
} from 'lucide-react';

const Sidebar = () => {
  const navItems = [
    { to: '/', icon: LayoutDashboard, label: 'Dashboard' },
    { to: '/clients', icon: Users, label: 'Clients' },
    { to: '/matters', icon: Briefcase, label: 'Matters' },
    { to: '/time-entries', icon: Clock, label: 'Time Entries' },
    { to: '/documents', icon: FileText, label: 'Documents' },
    { to: '/email', icon: Mail, label: 'Email' },
    { to: '/calendar', icon: Calendar, label: 'Calendar' },
    { to: '/settings', icon: Settings, label: 'Settings' },
  ];

  return (
    <div className="h-screen w-64 bg-gray-900 text-white p-4 fixed left-0 top-0">
      <div className="flex flex-col items-center mb-8 pt-4">
        <div className="p-3 bg-blue-600 rounded-xl mb-3">
          <Scale className="w-8 h-8" />
        </div>
        <div className="text-center">
          <h1 className="text-xl font-bold tracking-tight">Outsider Legal Tech</h1>
          <p className="text-sm text-blue-400 font-medium">OLT</p>
        </div>
      </div>
      <nav className="space-y-2">
        {navItems.map((item) => (
          <NavLink
            key={item.to}
            to={item.to}
            className={({ isActive }) =>
              `flex items-center gap-3 px-4 py-2 rounded-lg transition-colors ${
                isActive
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-300 hover:bg-gray-800'
              }`
            }
          >
            <item.icon className="w-5 h-5" />
            <span>{item.label}</span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;