import { useState, useEffect } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { useFirestore } from './useFirestore';
import { useAuth } from '../contexts/AuthContext';
import type { Client } from '../types';

export const useClients = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { getQuery, add, update, remove } = useFirestore<Client>('clients');

  useEffect(() => {
    if (!user) {
      setClients([]);
      setLoading(false);
      return;
    }

    try {
      const q = getQuery();
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const clientsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Client));
        setClients(clientsData);
        setLoading(false);
      });

      return () => unsubscribe();
    } catch (error) {
      console.error('Error fetching clients:', error);
      setLoading(false);
    }
  }, [user]);

  return {
    clients,
    loading,
    addClient: add,
    updateClient: update,
    deleteClient: remove
  };
};