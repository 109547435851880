import React from 'react';
import { Search, Settings, LogOut } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useUserSettings } from '../hooks/useUserSettings';
import { useNavigate } from 'react-router-dom';
import Timer from './timer/Timer';
import toast from 'react-hot-toast';

const Header = () => {
  const { logout, user } = useAuth();
  const { settings } = useUserSettings();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
      toast.success('Logged out successfully');
    } catch (error) {
      toast.error('Failed to log out');
    }
  };

  const displayName = settings?.displayName || user?.email;

  return (
    <header className="h-16 bg-white border-b fixed top-0 right-0 left-64 px-6 flex items-center justify-between z-10">
      <div className="flex items-center flex-1">
        <div className="relative w-96">
          <input
            type="text"
            placeholder="Search..."
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
          />
          <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
        </div>
        <div className="ml-6">
          <Timer />
        </div>
      </div>
      <div className="flex items-center gap-4">
        <button 
          onClick={() => navigate('/settings')}
          className="p-2 hover:bg-gray-100 rounded-full"
        >
          <Settings className="w-5 h-5 text-gray-600" />
        </button>
        <button 
          onClick={handleLogout}
          className="p-2 hover:bg-gray-100 rounded-full text-gray-600"
        >
          <LogOut className="w-5 h-5" />
        </button>
        <div className="flex items-center gap-3 ml-4">
          <div className="w-8 h-8 rounded-full bg-gray-100 overflow-hidden">
            {settings?.photoURL ? (
              <img
                src={settings.photoURL}
                alt={displayName}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center bg-blue-600 text-white font-medium">
                {displayName?.[0].toUpperCase()}
              </div>
            )}
          </div>
          <div className="text-sm">
            <p className="font-medium text-gray-700">{displayName}</p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;