import React, { useState } from 'react';
import { Plus, Edit2, Trash2, X } from 'lucide-react';
import { useSnippets } from '../../hooks/useSnippets';
import toast from 'react-hot-toast';
import type { Snippet } from '../../types';

const SnippetsSettings = () => {
  const { snippets, addSnippet, updateSnippet, deleteSnippet, loading } = useSnippets();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingSnippet, setEditingSnippet] = useState<Snippet | null>(null);
  const [description, setDescription] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const snippetData = {
        content: description,
        title: description.slice(0, 50) // Use first 50 chars as title
      };

      if (editingSnippet) {
        await updateSnippet(editingSnippet.id, snippetData);
        toast.success('Snippet updated successfully');
      } else {
        await addSnippet(snippetData);
        toast.success('Snippet created successfully');
      }
      setIsModalOpen(false);
      setEditingSnippet(null);
      setDescription('');
    } catch (error) {
      toast.error(editingSnippet ? 'Failed to update snippet' : 'Failed to create snippet');
    }
  };

  const handleEdit = (snippet: Snippet) => {
    setEditingSnippet(snippet);
    setDescription(snippet.content);
    setIsModalOpen(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteSnippet(id);
      toast.success('Snippet deleted successfully');
    } catch (error) {
      toast.error('Failed to delete snippet');
    }
  };

  const handleOpenModal = () => {
    setEditingSnippet(null);
    setDescription('');
    setIsModalOpen(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-xl font-semibold">Time Entry Descriptions</h2>
          <p className="text-sm text-gray-500 mt-1">
            Create reusable descriptions for your time entries
          </p>
        </div>
        <button
          onClick={handleOpenModal}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          New Description
        </button>
      </div>

      <div className="space-y-4">
        {snippets.length === 0 ? (
          <p className="text-gray-500 text-center py-8">No descriptions created yet</p>
        ) : (
          snippets.map((snippet) => (
            <div
              key={snippet.id}
              className="border rounded-lg p-4 hover:border-blue-200 transition-colors"
            >
              <div className="flex justify-between items-start">
                <p className="text-gray-600">{snippet.content}</p>
                <div className="flex gap-2 ml-4">
                  <button
                    onClick={() => handleEdit(snippet)}
                    className="text-blue-600 hover:text-blue-700"
                  >
                    <Edit2 className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(snippet.id)}
                    className="text-red-600 hover:text-red-700"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-lg w-full max-w-lg">
            <div className="flex justify-between items-center p-6 border-b">
              <h2 className="text-xl font-semibold">
                {editingSnippet ? 'Edit Description' : 'New Description'}
              </h2>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="p-6 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  required
                  rows={4}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="e.g., Initial consultation with client regarding case strategy and next steps"
                />
              </div>

              <div className="flex justify-end gap-3 mt-6">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
                >
                  {editingSnippet ? 'Update Description' : 'Create Description'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SnippetsSettings;