import { useState, useEffect } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { useFirestore } from './useFirestore';
import { useAuth } from '../contexts/AuthContext';
import type { TimeEntry } from '../types';

export const useTimeEntries = () => {
  const [timeEntries, setTimeEntries] = useState<TimeEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { getQuery, add, update, remove } = useFirestore<TimeEntry>('timeEntries');

  useEffect(() => {
    if (!user) {
      setTimeEntries([]);
      setLoading(false);
      return;
    }

    try {
      const q = getQuery();
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const entriesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as TimeEntry));
        setTimeEntries(entriesData);
        setLoading(false);
      });

      return () => unsubscribe();
    } catch (error) {
      console.error('Error fetching time entries:', error);
      setLoading(false);
    }
  }, [user]);

  return {
    timeEntries,
    loading,
    addTimeEntry: add,
    updateTimeEntry: update,
    deleteTimeEntry: remove
  };
};