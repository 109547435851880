import React from 'react';
import { Mail, ExternalLink } from 'lucide-react';

const Email = () => {
  const emailServices = [
    {
      name: 'Gmail',
      url: 'https://mail.google.com',
      description: 'Open Gmail inbox'
    },
    {
      name: 'Outlook',
      url: 'https://outlook.office.com/mail',
      description: 'Open Outlook inbox'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Email</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {emailServices.map((service) => (
          <a
            key={service.name}
            href={service.url}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="flex items-center gap-3">
              <div className="p-2 bg-blue-100 rounded-lg">
                <Mail className="w-6 h-6 text-blue-600" />
              </div>
              <div>
                <h3 className="font-medium text-gray-900">{service.name}</h3>
                <p className="text-sm text-gray-500 mt-1">{service.description}</p>
              </div>
              <ExternalLink className="w-4 h-4 text-gray-400 ml-auto" />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Email;