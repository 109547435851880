import React from 'react';
import { FileText, ExternalLink } from 'lucide-react';
import { useDocumentTemplates } from '../../hooks/useDocumentTemplates';
import { useClients } from '../../hooks/useClients';
import { useMatters } from '../../hooks/useMatters';

const RecentDocuments = () => {
  const { templates } = useDocumentTemplates();
  const { clients } = useClients();
  const { matters } = useMatters();

  const getClientName = (clientId: string) => {
    return clients.find(c => c.id === clientId)?.name || 'No Client';
  };

  const getMatterTitle = (matterId: string) => {
    return matters.find(m => m.id === matterId)?.title || 'No Matter';
  };

  const recentDocuments = templates
    .sort((a, b) => {
      try {
        const dateA = a.createdAt?.toDate?.() || new Date(a.createdAt);
        const dateB = b.createdAt?.toDate?.() || new Date(b.createdAt);
        return dateB.getTime() - dateA.getTime();
      } catch (error) {
        return 0;
      }
    })
    .slice(0, 5);

  return (
    <div className="bg-white rounded-xl shadow-sm">
      <div className="p-6">
        <div className="flex items-center gap-2 mb-4">
          <FileText className="w-5 h-5 text-blue-600" />
          <h2 className="text-lg font-semibold">Recent Documents</h2>
        </div>
        {recentDocuments.length === 0 ? (
          <p className="text-gray-500 text-center py-4">No recent documents</p>
        ) : (
          <div className="space-y-4">
            {recentDocuments.map((doc) => (
              <div key={doc.id} className="flex items-start gap-4 p-3 bg-gray-50 rounded-lg">
                <div className="flex-1">
                  <div className="flex justify-between items-start">
                    <div>
                      <p className="font-medium text-gray-900">{doc.title}</p>
                      <p className="text-sm text-gray-600 mt-1">{doc.description}</p>
                      <div className="flex gap-2 mt-2 text-sm text-gray-500">
                        <span>{getClientName(doc.clientId || '')}</span>
                        {doc.matterId && (
                          <>
                            <span>•</span>
                            <span>{getMatterTitle(doc.matterId)}</span>
                          </>
                        )}
                      </div>
                    </div>
                    <a
                      href={doc.fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-700"
                    >
                      <ExternalLink className="w-4 h-4" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentDocuments;