import React from 'react';
import { FileText, Edit2 } from 'lucide-react';
import { format } from 'date-fns';
import { getDocumentService } from '../../utils/documentUtils';

interface DocumentCardProps {
  document: {
    id: string;
    title: string;
    description: string;
    fileUrl: string;
    category?: string;
    createdAt?: any;
    clientId?: string;
    matterId?: string;
  };
  onDelete: () => void;
  onEdit: () => void;
  clientName?: string;
  matterTitle?: string;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ 
  document, 
  onDelete, 
  onEdit,
  clientName,
  matterTitle
}) => {
  const service = getDocumentService(document.fileUrl);
  
  const getServiceLabel = () => {
    switch (service) {
      case 'google':
        return 'Google Docs';
      case 'microsoft':
        return 'Microsoft Office';
      default:
        return 'External Document';
    }
  };

  return (
    <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow">
      <div className="flex items-start gap-4">
        <div className="p-2 bg-blue-100 rounded-lg">
          <FileText className="w-6 h-6 text-blue-600" />
        </div>
        <div className="flex-1">
          <h3 className="font-medium text-gray-900">{document.title}</h3>
          <p className="text-sm text-gray-500 mt-1">{document.description}</p>
          <div className="flex flex-wrap gap-2 mt-2">
            {document.category && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                {document.category}
              </span>
            )}
            {clientName && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                {clientName}
              </span>
            )}
            {matterTitle && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                {matterTitle}
              </span>
            )}
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
              {getServiceLabel()}
            </span>
            {document.createdAt && (
              <span className="text-xs text-gray-500">
                {format(document.createdAt.toDate(), 'MMM d, yyyy')}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-end gap-2">
        <button
          onClick={onEdit}
          className="text-sm text-blue-600 hover:text-blue-700 font-medium flex items-center gap-1"
        >
          <Edit2 className="w-4 h-4" />
          Edit
        </button>
        <a
          href={document.fileUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-blue-600 hover:text-blue-700 font-medium"
        >
          Open Document
        </a>
        <button
          onClick={onDelete}
          className="text-sm text-red-600 hover:text-red-700 font-medium"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DocumentCard;