import React, { useState } from 'react';
import { Plus, FileText, Search, MessageSquare } from 'lucide-react';
import { useDocumentTemplates } from '../hooks/useDocumentTemplates';
import { useClients } from '../hooks/useClients';
import { useMatters } from '../hooks/useMatters';
import DocumentUpload from '../components/documents/DocumentUpload';
import DocumentFilters from '../components/documents/DocumentFilters';
import DocumentCard from '../components/documents/DocumentCard';
import { format, isWithinInterval, parseISO } from 'date-fns';
import toast from 'react-hot-toast';

const Documents = () => {
  const { templates, loading, deleteTemplate } = useDocumentTemplates();
  const { clients } = useClients();
  const { matters } = useMatters();
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [editDocument, setEditDocument] = useState(null);
  const [filters, setFilters] = useState({
    client: '',
    matter: '',
    dateFrom: '',
    dateTo: ''
  });

  const handleFilterChange = (key: string, value: string) => {
    setFilters(prev => ({
      ...prev,
      [key]: value,
      ...(key === 'client' && { matter: '' })
    }));
  };

  const filteredDocuments = templates.filter(doc => {
    const matchesSearch = 
      doc.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.description.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesClient = !filters.client || doc.clientId === filters.client;
    const matchesMatter = !filters.matter || doc.matterId === filters.matter;

    let matchesDate = true;
    if (filters.dateFrom || filters.dateTo) {
      const docDate = doc.createdAt?.toDate();
      if (docDate) {
        const start = filters.dateFrom ? parseISO(filters.dateFrom) : new Date(0);
        const end = filters.dateTo ? parseISO(filters.dateTo) : new Date();
        matchesDate = isWithinInterval(docDate, { start, end });
      }
    }

    return matchesSearch && matchesClient && matchesMatter && matchesDate;
  });

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      try {
        await deleteTemplate(id);
        toast.success('Document deleted successfully');
      } catch (error) {
        toast.error('Failed to delete document');
      }
    }
  };

  const getClientName = (clientId: string) => {
    return clients.find(c => c.id === clientId)?.name;
  };

  const getMatterTitle = (matterId: string) => {
    return matters.find(m => m.id === matterId)?.title;
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Documents</h1>
        <div className="flex gap-3">
          <a
            href="https://chatgpt.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 flex items-center gap-2"
          >
            <MessageSquare className="w-4 h-4" />
            ChatGPT
          </a>
          <button
            onClick={() => {
              setEditDocument(null);
              setIsUploadOpen(true);
            }}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
          >
            <Plus className="w-4 h-4" />
            Upload Document
          </button>
        </div>
      </div>

      <div className="space-y-4">
        <div className="relative">
          <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
          <input
            type="text"
            placeholder="Search documents..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <DocumentFilters 
          filters={filters}
          onFilterChange={handleFilterChange}
        />
      </div>

      {loading ? (
        <div className="text-center py-8 text-gray-500">Loading documents...</div>
      ) : filteredDocuments.length === 0 ? (
        <div className="text-center py-8 text-gray-500">No documents found</div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredDocuments.map((doc) => (
            <DocumentCard
              key={doc.id}
              document={doc}
              onDelete={() => handleDelete(doc.id)}
              onEdit={() => {
                setEditDocument(doc);
                setIsUploadOpen(true);
              }}
              clientName={getClientName(doc.clientId)}
              matterTitle={getMatterTitle(doc.matterId)}
            />
          ))}
        </div>
      )}

      <DocumentUpload
        isOpen={isUploadOpen}
        onClose={() => {
          setIsUploadOpen(false);
          setEditDocument(null);
        }}
        onSuccess={() => {
          setIsUploadOpen(false);
          setEditDocument(null);
        }}
        editDocument={editDocument}
      />
    </div>
  );
};

export default Documents;