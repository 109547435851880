import React, { useState } from 'react';
import { format } from 'date-fns';
import { DollarSign, FileText, Printer } from 'lucide-react';
import { useTimeEntries } from '../../hooks/useTimeEntries';
import { useMatters } from '../../hooks/useMatters';
import { useClients } from '../../hooks/useClients';
import { useUserSettings } from '../../hooks/useUserSettings';
import InvoicePreview from './InvoicePreview';

const InvoiceGenerator = () => {
  const { timeEntries } = useTimeEntries();
  const { matters } = useMatters();
  const { clients } = useClients();
  const { settings } = useUserSettings();
  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const [showPreview, setShowPreview] = useState(false);

  // Filter for only unbilled entries
  const unbilledEntries = timeEntries.filter(entry => !entry.billed);

  const handleToggleSelect = (id: string) => {
    setSelectedEntries(prev =>
      prev.includes(id) ? prev.filter(entryId => entryId !== id) : [...prev, id]
    );
  };

  const handleToggleSelectAll = () => {
    setSelectedEntries(prev =>
      prev.length === unbilledEntries.length ? [] : unbilledEntries.map(entry => entry.id)
    );
  };

  const getClientName = (clientId: string) => {
    return clients.find(c => c.id === clientId)?.name || 'Unknown Client';
  };

  const getMatterTitle = (matterId: string) => {
    return matters.find(m => m.id === matterId)?.title || 'Unknown Matter';
  };

  const calculateTotal = (entries: typeof timeEntries) => {
    return entries.reduce((total, entry) => {
      if (entry.billingType === 'fixed') {
        return total + (entry.fixedFeeAmount || 0);
      }
      return total + (entry.hours * entry.rate);
    }, 0);
  };

  const selectedTimeEntries = unbilledEntries.filter(entry => 
    selectedEntries.includes(entry.id)
  );

  const groupedEntries = selectedTimeEntries.reduce((acc, entry) => {
    const matterId = entry.matterId;
    if (!acc[matterId]) {
      acc[matterId] = [];
    }
    acc[matterId].push(entry);
    return acc;
  }, {} as Record<string, typeof timeEntries>);

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-xl shadow-sm">
        <div className="p-4 border-b flex justify-between items-center">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={selectedEntries.length === unbilledEntries.length}
              onChange={handleToggleSelectAll}
              className="rounded border-gray-300"
            />
            <span className="text-sm text-gray-600">
              {selectedEntries.length} entries selected
            </span>
          </div>
          <button
            onClick={() => setShowPreview(true)}
            disabled={selectedEntries.length === 0}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
          >
            <FileText className="w-4 h-4" />
            Generate Invoice
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-4 py-3 text-left">
                  <span className="sr-only">Select</span>
                </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Matter</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Client</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Description</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Hours/Fee</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Rate</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Amount</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {unbilledEntries.map((entry) => (
                <tr key={entry.id} className="hover:bg-gray-50">
                  <td className="px-4 py-3">
                    <input
                      type="checkbox"
                      checked={selectedEntries.includes(entry.id)}
                      onChange={() => handleToggleSelect(entry.id)}
                      className="rounded border-gray-300"
                    />
                  </td>
                  <td className="px-4 py-3 text-sm">
                    {format(new Date(entry.date), 'MMM d, yyyy')}
                  </td>
                  <td className="px-4 py-3 text-sm">{getMatterTitle(entry.matterId)}</td>
                  <td className="px-4 py-3 text-sm">{getClientName(entry.clientId)}</td>
                  <td className="px-4 py-3 text-sm">{entry.description}</td>
                  <td className="px-4 py-3 text-sm">
                    {entry.billingType === 'fixed' ? 
                      entry.fixedFeeTitle : 
                      `${entry.hours} hrs`}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    {entry.billingType === 'fixed' ? 
                      '-' : 
                      `$${entry.rate}/hr`}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    ${entry.billingType === 'fixed' ? 
                      entry.fixedFeeAmount?.toFixed(2) : 
                      (entry.hours * entry.rate).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
            {selectedEntries.length > 0 && (
              <tfoot>
                <tr className="bg-gray-50 font-medium">
                  <td colSpan={7} className="px-4 py-3 text-right">Total:</td>
                  <td className="px-4 py-3">${calculateTotal(selectedTimeEntries).toFixed(2)}</td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>

      {showPreview && (
        <InvoicePreview
          isOpen={showPreview}
          onClose={() => setShowPreview(false)}
          timeEntries={selectedTimeEntries}
          groupedEntries={groupedEntries}
          settings={settings}
          matters={matters}
          clients={clients}
        />
      )}
    </div>
  );
};

export default InvoiceGenerator;