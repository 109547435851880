import { useState, useEffect } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { useFirestore } from './useFirestore';
import { useAuth } from '../contexts/AuthContext';
import type { Matter } from '../types';

export const useMatters = () => {
  const [matters, setMatters] = useState<Matter[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { getQuery, add, update, remove } = useFirestore<Matter>('matters');

  useEffect(() => {
    if (!user) {
      setMatters([]);
      setLoading(false);
      return;
    }

    try {
      const q = getQuery();
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const mattersData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Matter));
        setMatters(mattersData);
        setLoading(false);
      });

      return () => unsubscribe();
    } catch (error) {
      console.error('Error fetching matters:', error);
      setLoading(false);
    }
  }, [user]);

  return {
    matters,
    loading,
    addMatter: add,
    updateMatter: update,
    deleteMatter: remove
  };
};