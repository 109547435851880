import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useTimeEntries } from '../../hooks/useTimeEntries';
import { useMatters } from '../../hooks/useMatters';
import { useClients } from '../../hooks/useClients';
import { useUserSettings } from '../../hooks/useUserSettings';
import toast from 'react-hot-toast';

interface TimerEntryFormProps {
  isOpen: boolean;
  onClose: () => void;
  duration: number;
}

const TimerEntryForm: React.FC<TimerEntryFormProps> = ({ isOpen, onClose, duration }) => {
  const { addTimeEntry } = useTimeEntries();
  const { matters } = useMatters();
  const { clients } = useClients();
  const { settings } = useUserSettings();
  const [formData, setFormData] = useState({
    matterId: '',
    clientId: '',
    description: '',
    billable: true
  });

  const hours = duration / 3600; // Convert seconds to hours

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await addTimeEntry({
        ...formData,
        hours,
        rate: settings?.hourlyRate || 0,
        date: new Date().toISOString().split('T')[0],
        billingType: 'hourly'
      });
      toast.success('Time entry created successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to create time entry');
    }
  };

  if (!isOpen) return null;

  const filteredMatters = matters.filter(
    matter => !formData.clientId || matter.clientId === formData.clientId
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-lg">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Create Time Entry</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Client
              </label>
              <select
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.clientId}
                onChange={(e) => setFormData(prev => ({ 
                  ...prev, 
                  clientId: e.target.value,
                  matterId: '' 
                }))}
              >
                <option value="">Select a client</option>
                {clients.map(client => (
                  <option key={client.id} value={client.id}>{client.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Matter
              </label>
              <select
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData.matterId}
                onChange={(e) => setFormData(prev => ({ ...prev, matterId: e.target.value }))}
                disabled={!formData.clientId}
              >
                <option value="">Select a matter</option>
                {filteredMatters.map(matter => (
                  <option key={matter.id} value={matter.id}>{matter.title}</option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              required
              rows={3}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="billable"
              className="rounded border-gray-300"
              checked={formData.billable}
              onChange={(e) => setFormData(prev => ({ ...prev, billable: e.target.checked }))}
            />
            <label htmlFor="billable" className="ml-2 text-sm text-gray-700">
              Billable
            </label>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
            >
              Save Entry
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TimerEntryForm;