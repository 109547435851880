import React, { useState } from 'react';
import { format } from 'date-fns';
import { Edit2, Trash2, Receipt, DollarSign, ArrowLeft } from 'lucide-react';
import { useTimeEntries } from '../../hooks/useTimeEntries';
import { useMatters } from '../../hooks/useMatters';
import { useClients } from '../../hooks/useClients';
import type { TimeEntry } from '../../types';
import toast from 'react-hot-toast';

interface TimeEntriesTableProps {
  onEdit: (entry: TimeEntry) => void;
}

const TimeEntriesTable: React.FC<TimeEntriesTableProps> = ({ onEdit }) => {
  const { timeEntries, loading, deleteTimeEntry, updateTimeEntry } = useTimeEntries();
  const { matters } = useMatters();
  const { clients } = useClients();
  const [activeTab, setActiveTab] = useState<'unbilled' | 'billed' | 'paid'>('unbilled');

  const handleDelete = async (id: string) => {
    try {
      await deleteTimeEntry(id);
      toast.success('Time entry deleted successfully');
    } catch (error) {
      toast.error('Failed to delete time entry');
    }
  };

  const handleMarkAsBilled = async (entry: TimeEntry) => {
    try {
      await updateTimeEntry(entry.id, { billed: true });
      toast.success('Entry marked as billed');
    } catch (error) {
      toast.error('Failed to mark entry as billed');
    }
  };

  const handleMarkAsPaid = async (entry: TimeEntry) => {
    try {
      await updateTimeEntry(entry.id, { paid: true });
      toast.success('Entry marked as paid');
    } catch (error) {
      toast.error('Failed to mark entry as paid');
    }
  };

  const handleRevertToBilled = async (entry: TimeEntry) => {
    try {
      await updateTimeEntry(entry.id, { paid: false });
      toast.success('Entry reverted to billed status');
    } catch (error) {
      toast.error('Failed to revert entry status');
    }
  };

  const getMatterTitle = (matterId: string) => {
    return matters.find(m => m.id === matterId)?.title || 'Unknown Matter';
  };

  const getClientName = (clientId: string) => {
    return clients.find(c => c.id === clientId)?.name || 'Unknown Client';
  };

  const filteredEntries = timeEntries.filter(entry => {
    if (activeTab === 'unbilled') return !entry.billed;
    if (activeTab === 'billed') return entry.billed && !entry.paid;
    return entry.paid;
  });

  if (loading) {
    return <div className="text-center py-8 text-gray-500">Loading time entries...</div>;
  }

  return (
    <div>
      <div className="border-b mb-4">
        <div className="flex gap-4 px-4">
          <button
            onClick={() => setActiveTab('unbilled')}
            className={`py-2 px-4 border-b-2 ${
              activeTab === 'unbilled'
                ? 'border-blue-600 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
          >
            Unbilled Entries
          </button>
          <button
            onClick={() => setActiveTab('billed')}
            className={`py-2 px-4 border-b-2 ${
              activeTab === 'billed'
                ? 'border-blue-600 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
          >
            Billed Entries
          </button>
          <button
            onClick={() => setActiveTab('paid')}
            className={`py-2 px-4 border-b-2 ${
              activeTab === 'paid'
                ? 'border-blue-600 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
          >
            Paid Entries
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Matter</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Client</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Description</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Hours/Fee</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Rate</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Amount</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredEntries.map((entry) => (
              <tr key={entry.id} className="hover:bg-gray-50">
                <td className="px-4 py-3 text-sm">
                  {format(new Date(entry.date), 'MMM d, yyyy')}
                </td>
                <td className="px-4 py-3 text-sm">{getMatterTitle(entry.matterId)}</td>
                <td className="px-4 py-3 text-sm">{getClientName(entry.clientId)}</td>
                <td className="px-4 py-3 text-sm">{entry.description}</td>
                <td className="px-4 py-3 text-sm">
                  {entry.billingType === 'fixed' ? 
                    entry.fixedFeeTitle : 
                    `${entry.hours} hrs`}
                </td>
                <td className="px-4 py-3 text-sm">
                  {entry.billingType === 'fixed' ? 
                    '-' : 
                    `$${entry.rate}/hr`}
                </td>
                <td className="px-4 py-3 text-sm">
                  ${entry.billingType === 'fixed' ? 
                    entry.fixedFeeAmount?.toFixed(2) : 
                    (entry.hours * entry.rate).toFixed(2)}
                </td>
                <td className="px-4 py-3">
                  <div className="flex gap-2">
                    {!entry.billed && (
                      <button
                        onClick={() => handleMarkAsBilled(entry)}
                        className="text-green-600 hover:text-green-700"
                        title="Mark as Billed"
                      >
                        <Receipt className="w-4 h-4" />
                      </button>
                    )}
                    {entry.billed && !entry.paid && (
                      <button
                        onClick={() => handleMarkAsPaid(entry)}
                        className="text-green-600 hover:text-green-700"
                        title="Mark as Paid"
                      >
                        <DollarSign className="w-4 h-4" />
                      </button>
                    )}
                    {entry.paid ? (
                      <button
                        onClick={() => handleRevertToBilled(entry)}
                        className="text-orange-600 hover:text-orange-700"
                        title="Revert to Billed"
                      >
                        <ArrowLeft className="w-4 h-4" />
                      </button>
                    ) : (
                      <>
                        <button
                          onClick={() => onEdit(entry)}
                          className="text-blue-600 hover:text-blue-700"
                        >
                          <Edit2 className="w-4 h-4" />
                        </button>
                        <button
                          onClick={() => handleDelete(entry.id)}
                          className="text-red-600 hover:text-red-700"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TimeEntriesTable;