import React, { useState } from 'react';
import { Plus, Search, MoreVertical } from 'lucide-react';
import { useMatters } from '../hooks/useMatters';
import NewMatterForm from '../components/matters/NewMatterForm';
import MatterDetailsModal from '../components/matters/MatterDetailsModal';
import type { Matter } from '../types';

const Matters = () => {
  const { matters, loading } = useMatters();
  const [isNewMatterOpen, setIsNewMatterOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMatter, setSelectedMatter] = useState<Matter | null>(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const getStatusBadgeColor = (status: Matter['status']) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'closed':
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getBillingTypeBadgeColor = (billingType: Matter['billingType']) => {
    switch (billingType) {
      case 'hourly':
        return 'bg-blue-100 text-blue-800';
      case 'fixed':
        return 'bg-purple-100 text-purple-800';
      case 'contingency':
        return 'bg-orange-100 text-orange-800';
    }
  };

  const filteredMatters = matters.filter(matter =>
    matter.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    matter.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleViewMatter = (matter: Matter) => {
    setSelectedMatter(matter);
    setIsDetailsOpen(true);
  };

  const handleEditMatter = () => {
    setIsDetailsOpen(false);
    setIsNewMatterOpen(true);
  };

  return (
    <>
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Matters</h1>
          <button
            onClick={() => {
              setSelectedMatter(null);
              setIsNewMatterOpen(true);
            }}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
          >
            <Plus className="w-4 h-4" />
            New Matter
          </button>
        </div>

        <div className="bg-white rounded-xl shadow-sm">
          <div className="p-4 border-b">
            <div className="relative w-96">
              <input
                type="text"
                placeholder="Search matters..."
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Matter
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Billing Type
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Start Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Due Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {loading ? (
                  <tr>
                    <td colSpan={6} className="px-6 py-8 text-center text-gray-500">
                      Loading matters...
                    </td>
                  </tr>
                ) : filteredMatters.length === 0 ? (
                  <tr>
                    <td colSpan={6} className="px-6 py-8 text-center text-gray-500">
                      No matters found
                    </td>
                  </tr>
                ) : (
                  filteredMatters.map((matter) => (
                    <tr key={matter.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4">
                        <div className="text-sm font-medium text-gray-900">{matter.title}</div>
                        <div className="text-sm text-gray-500">{matter.description}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeColor(matter.status)}`}>
                          {matter.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getBillingTypeBadgeColor(matter.billingType)}`}>
                          {matter.billingType}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">{matter.startDate}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">{matter.dueDate}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button 
                          onClick={() => handleViewMatter(matter)}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <MoreVertical className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <NewMatterForm
        isOpen={isNewMatterOpen}
        onClose={() => {
          setIsNewMatterOpen(false);
          setSelectedMatter(null);
        }}
        onSuccess={() => {
          setIsNewMatterOpen(false);
          setSelectedMatter(null);
        }}
        editMatter={selectedMatter}
      />

      {selectedMatter && (
        <MatterDetailsModal
          matter={selectedMatter}
          isOpen={isDetailsOpen}
          onClose={() => {
            setIsDetailsOpen(false);
            setSelectedMatter(null);
          }}
          onEdit={handleEditMatter}
        />
      )}
    </>
  );
};

export default Matters;