import React from 'react';
import { ExternalLink } from 'lucide-react';

const Calendar = () => {
  const calendarLinks = [
    {
      name: 'Google Calendar',
      url: 'https://calendar.google.com',
      description: 'Open Google Calendar in a new tab'
    },
    {
      name: 'Microsoft Calendar',
      url: 'https://outlook.office.com/calendar',
      description: 'Open Microsoft Calendar in a new tab'
    }
  ];

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold">Calendar</h1>
      
      <div className="grid gap-4 md:grid-cols-2 max-w-2xl">
        {calendarLinks.map((link) => (
          <a
            key={link.name}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-3 p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow"
          >
            <ExternalLink className="w-6 h-6 text-blue-600" />
            <div>
              <h2 className="font-medium text-gray-900">{link.name}</h2>
              <p className="text-sm text-gray-500">{link.description}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Calendar;