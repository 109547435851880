import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';

export const useStorage = () => {
  const { user } = useAuth();
  const storage = getStorage();

  const uploadFile = async (file: File, path: string): Promise<string> => {
    if (!user) throw new Error('No authenticated user');

    const storageRef = ref(storage, `users/${user.uid}/${path}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };

  return {
    uploadFile
  };
};