import { useState, useEffect } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { useFirestore } from './useFirestore';
import { useAuth } from '../contexts/AuthContext';
import type { FixedFeeTemplate } from '../types';

export const useFixedFeeTemplates = () => {
  const [templates, setTemplates] = useState<FixedFeeTemplate[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { getQuery, add, update, remove } = useFirestore<FixedFeeTemplate>('fixedFeeTemplates');

  useEffect(() => {
    if (!user) {
      setTemplates([]);
      setLoading(false);
      return;
    }

    try {
      const q = getQuery();
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const templatesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as FixedFeeTemplate));
        setTemplates(templatesData);
        setLoading(false);
      });

      return () => unsubscribe();
    } catch (error) {
      console.error('Error fetching fixed fee templates:', error);
      setLoading(false);
    }
  }, [user]);

  return {
    templates,
    loading,
    addTemplate: add,
    updateTemplate: update,
    deleteTemplate: remove
  };
};