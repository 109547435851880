import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import TimeEntriesTable from '../components/time/TimeEntriesTable';
import NewTimeEntryForm from '../components/time/NewTimeEntryForm';
import InvoiceGenerator from '../components/invoices/InvoiceGenerator';
import type { TimeEntry } from '../types';

const TimeEntries = () => {
  const [isNewEntryOpen, setIsNewEntryOpen] = useState(false);
  const [editEntry, setEditEntry] = useState<TimeEntry | undefined>();
  const [activeTab, setActiveTab] = useState<'entries' | 'invoice'>('entries');

  const handleEdit = (entry: TimeEntry) => {
    setEditEntry(entry);
    setIsNewEntryOpen(true);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex gap-4">
          <button
            onClick={() => setActiveTab('entries')}
            className={`px-4 py-2 rounded-lg ${
              activeTab === 'entries'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            Time Entries
          </button>
          <button
            onClick={() => setActiveTab('invoice')}
            className={`px-4 py-2 rounded-lg ${
              activeTab === 'invoice'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            Generate Invoice
          </button>
        </div>
        {activeTab === 'entries' && (
          <button
            onClick={() => {
              setEditEntry(undefined);
              setIsNewEntryOpen(true);
            }}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
          >
            <Plus className="w-4 h-4" />
            New Time Entry
          </button>
        )}
      </div>

      <div className="bg-white rounded-xl shadow-sm">
        {activeTab === 'entries' ? (
          <TimeEntriesTable onEdit={handleEdit} />
        ) : (
          <InvoiceGenerator />
        )}
      </div>

      <NewTimeEntryForm
        isOpen={isNewEntryOpen}
        onClose={() => {
          setIsNewEntryOpen(false);
          setEditEntry(undefined);
        }}
        onSuccess={() => {
          setIsNewEntryOpen(false);
          setEditEntry(undefined);
        }}
        editEntry={editEntry}
      />
    </div>
  );
};

export default TimeEntries;