import React, { useState, useEffect } from 'react';
import { Clock, Play, Pause, Square, Plus } from 'lucide-react';
import { formatDuration } from '../../utils/timeUtils';
import TimerEntryForm from './TimerEntryForm';

const Timer = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const [showEntryForm, setShowEntryForm] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRunning) {
      interval = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning]);

  const handleStart = () => setIsRunning(true);
  const handlePause = () => setIsRunning(false);
  const handleStop = () => {
    setIsRunning(false);
    if (time > 0) {
      setShowEntryForm(true);
    }
  };

  const handleReset = () => {
    setTime(0);
    setIsRunning(false);
  };

  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center gap-2 px-3 py-1.5 bg-gray-100 rounded-lg">
        <Clock className="w-4 h-4 text-gray-600" />
        <span className="font-medium text-gray-700">{formatDuration(time)}</span>
      </div>
      
      <div className="flex items-center gap-1">
        {!isRunning ? (
          <button
            onClick={handleStart}
            className="p-1.5 text-green-600 hover:bg-green-50 rounded-full"
            title="Start Timer"
          >
            <Play className="w-4 h-4" />
          </button>
        ) : (
          <button
            onClick={handlePause}
            className="p-1.5 text-orange-600 hover:bg-orange-50 rounded-full"
            title="Pause Timer"
          >
            <Pause className="w-4 h-4" />
          </button>
        )}
        
        <button
          onClick={handleStop}
          className="p-1.5 text-red-600 hover:bg-red-50 rounded-full"
          title="Stop and Save"
        >
          <Square className="w-4 h-4" />
        </button>

        <button
          onClick={() => setShowEntryForm(true)}
          className="p-1.5 text-blue-600 hover:bg-blue-50 rounded-full"
          title="Add Time Entry"
        >
          <Plus className="w-4 h-4" />
        </button>
      </div>

      <TimerEntryForm
        isOpen={showEntryForm}
        onClose={() => {
          setShowEntryForm(false);
          handleReset();
        }}
        duration={time}
      />
    </div>
  );
};

export default Timer;