import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoute } from '../components/PrivateRoute';
import ErrorBoundary from '../components/ErrorBoundary';
import Login from '../pages/Login';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Dashboard from '../pages/Dashboard';
import Clients from '../pages/Clients';
import Matters from '../pages/Matters';
import TimeEntries from '../pages/TimeEntries';
import Documents from '../pages/Documents';
import Email from '../pages/Email';
import Calendar from '../pages/Calendar';
import Settings from '../pages/Settings';

const AppRoutes = () => {
  return (
    <ErrorBoundary>
      <div className="min-h-screen bg-gray-50">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <div className="min-h-screen bg-gray-50">
                  <Sidebar />
                  <div className="ml-64">
                    <Header />
                    <main className="p-6 mt-16">
                      <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/clients" element={<Clients />} />
                        <Route path="/matters" element={<Matters />} />
                        <Route path="/time-entries" element={<TimeEntries />} />
                        <Route path="/documents" element={<Documents />} />
                        <Route path="/email" element={<Email />} />
                        <Route path="/calendar" element={<Calendar />} />
                        <Route path="/settings/*" element={<Settings />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                      </Routes>
                    </main>
                  </div>
                </div>
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </ErrorBoundary>
  );
};

export default AppRoutes;